import { useUser } from '@auth0/nextjs-auth0/client';
import { useState } from 'react';

import LoginButton from '../../components/buttons/LoginButon';
import LogoutButton from '../../components/buttons/LogoutButton';


export async function getStaticProps() {
  // Do not send this page on production
  if (process.env.NEXT_PUBLIC_OWN_DOMAIN === 'https://tali.ai') {
    return {
      notFound: true,
    };
  }

  return {
    props: {}, // will be passed to the page component as props
  };
}

// Temporary route to get to the login/logout functionalities
export default function Login() {
  const { user, error, isLoading } = useUser();
  const [counter, setCounter] = useState(0);

  let currentState = <div>Loading...</div>;
  if (!isLoading) {
    if (user) {
      currentState = <div>Logged in user is: {user.email}</div>;
    } else {
      currentState = <div>Logged Out</div>;
    }
  }
  if (error) {
    currentState = <div>Error {error}</div>;
  }

  return (
    <div>
      <div>This is a temporary page to display login state. In practice you should directly use the login/logout buttons without ever navigating to this page.</div>
      <button onClick={() => setCounter(counter + 1)}>Increment counter</button>
      <br />
      {currentState}
      <br />
      <LoginButton text="Login" />
      <br />
      <br />
      <LogoutButton />
    </div>
    );
}